import React from "react";

import styles from "../../styles/payment-methods.module.scss";
import { allowedPaymentMethods } from "../../constants";

export const PaymentMethodsComponent: React.FC = () => {
  return (
    <div style={{overflow: "hidden"}}>
      <div className={styles["payments-container"]}>
        <div className={styles.icons}>
          {allowedPaymentMethods
            .concat(allowedPaymentMethods)
            .concat(allowedPaymentMethods)
            .map((method, index) => (
              <div key={index} className={styles.icon}>
                <img
                  className="icon-img"
                  src={`/assets/img/icons/${method.icon}`}
                  alt={method.name}
                />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};
