import React from "react";

import styles from "../../styles/slider.module.scss";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { loadSlider } from "../../utils/api";

import { SwitchModalType } from "../../interfaces";
import { StaticSlideComponent } from "./StaticSlide.component";

interface SliderItem {
  title?: string;
  desc?: string;
  image?: string;
  type: "dinamyc" | "static";
  titleImage?: string;
  bannerImage?: string;
  logo?: string;
}

const staticSlidesItems: SliderItem[] = [
  {
    type: "static",
    desc: "Parlays, Teasers, Futures, Live Betting",
    titleImage: "/assets/img/mobile_banner_1.png",
    bannerImage: "/assets/img/banner_img_1.png",
  },
  {
    type: "static",
    desc: "Sports Betting, Casino, Racebook Live Dealers.",
    titleImage: "/assets/img/mobile_banner_2.png",
    bannerImage: "/assets/img/banner_img_2.png",
  },
];

const URL_BASE_LOGO_IMAGE = "https://sp.sportsaction77.com/tooltip/";
const URL_BASE_IMAGE = "https://sp.sportsaction77.com/tooltip/file/slider/";

interface Props {
  openModal: (modal: SwitchModalType) => void;
}

export const SliderComponent: React.FC<Props> = ({ openModal }) => {
  const [data, setData] = React.useState<SliderItem[]>([]);
  const [activeSlide, setActiveSlide] = React.useState<number>(0);

  const settings = {
    speed: 500,
    dots: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    adaptiveHeight: false,
    afterChange: (activeSlider: number) => {
      setActiveSlide(activeSlider);
    },
  };

  React.useEffect(() => {
    const getData = async () => {
      const res = await loadSlider();
      if (res.LIST?.IMAGES) {
        const dinamycItems = res.LIST.IMAGES.map((item: any) => {
          return {
            title: item["text1"],
            desc: item["text2"],
            type: "dinamyc",
            image: URL_BASE_IMAGE + item.image,
            logo: URL_BASE_LOGO_IMAGE + item.chanel,
          };
        });
        setData(dinamycItems);
      }
    };

    // getData();
  }, []);

  const getActiveSlideClass = (index: number) => {
    if (index === activeSlide) {
      return styles.active;
    }
    return "";
  };

  return (
    <div className={styles.container}>
      <div className={styles.slider}>
        <Slider {...settings}>
          {Array.from({ length: 4 }).map((_, index) => (
            <StaticSlideComponent
              key={index}
              index={index}
              openModal={() => openModal("register")}
            />
          ))}
          {data
            .filter(
              (item) =>
                item.image !==
                "https://sp.sportsaction77.com/tooltip/file/slider/8327_MicrosoftTeams-image_(16).png"
            )
            .map((item, index) => (
              <div style={{ width: "100%" }} key={`item_dynamic--${index}`}>
                <div
                  className={`${styles.slide} ${
                    styles["slide--dynamic"]
                  } ${getActiveSlideClass(index + staticSlidesItems.length)}`}
                >
                  <div className={styles.background}>
                    <img src={item.image} alt="" />
                  </div>
                  <div className={styles.content}>
                    <div className={styles.overlay}>
                      <div></div>
                    </div>
                    <div className={styles.container}>
                      <div
                        className={`${styles.logo} ${styles["logo--mobile"]}`}
                      >
                        <img src={item.logo} alt="" />
                      </div>
                      <h2>{item.title}</h2>
                      <p>{item.desc}</p>
                      <div
                        className={`${styles.logo} ${styles["logo--desktop"]}`}
                      >
                        <img src={item.logo} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </div>
  );
};
