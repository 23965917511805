import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import ModalComponent from "../../../components/ui/modal-terms.component";
import { checkEmail } from "../../../utils/api";

export const RegisterForm = () => {
  const [modalType, setModalType] = React.useState<string>("");
  const [visibleModal, setVisibleModal] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isPasswordValid, setIsPasswordValid] = React.useState<boolean>(true);
  // get url params
  const urlParams = new URLSearchParams(window.location.search);
  const aff = urlParams.get("aff");
  const captchaRef = React.useRef<ReCAPTCHA>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setIsLoading(true);
    const token = captchaRef?.current?.getValue();
    const password = formRef.current?.password.value;
    const passwordConfirm = formRef.current?.passwordConfirm.value;
    const email = formRef.current?.email.value;
    const confirmEmail = formRef.current?.confirmEmail.value;
    const terms = formRef.current?.terms.checked;
    if (!isPasswordValid) {
      alert("Password is not valid!!!");
      setIsLoading(false);
      return;
    }
    if (password !== passwordConfirm) {
      alert("Passwords do not match!!!");
      setIsLoading(false);
      return;
    }
    if (email !== confirmEmail) {
      alert("Emails do not match!!!");
      setIsLoading(false);
      return;
    }
    if (!terms) {
      alert("You must agree to the terms and conditions!!!");
      setIsLoading(false);
      return;
    }
    if (token) {
      const res = await checkEmail(formRef.current?.email.value);
      if ((res !== -1 && res !== "-1") || res.status === "error" || !res) {
        alert("Email already in use!!!");
        setIsLoading(false);
        return;
      } else {
        continueForm();
      }
    } else {
      alert("Please complete the captcha!!!");
      setIsLoading(false);
      return;
    }
  };

  const validatePassword = (password: string) => {
    if (!password) return true;
    const regex = /^[a-zA-Z0-9]*$/;
    if (password.length < 4 || password.length > 10 || !regex.test(password)) {
      return false;
    }
    return true;
  };

  const continueForm = () => {
    formRef.current?.submit();
    // setIsLoading(false);
  };

  const handleOpenModal = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    type: string
  ) => {
    e.preventDefault();
    setModalType(type);
    setVisibleModal(true);
  };

  return (
    <div className="lg-form">
      <form
        className="form"
        ref={formRef}
        id="create-account"
        method="post"
        action="https://wager.wagerattack.ag/sites/wagerattack.ag/createAccount.php"
        name="client register"
      >
        <div>
          <input
            type="text"
            name="firstname"
            placeholder="First name*"
            required
          />
        </div>
        <div>
          <input
            type="text"
            name="lastname"
            placeholder="Last name*"
            required
          />
        </div>
        <div>
          <input type="email" name="email" placeholder="Email*" required />
        </div>
        <div>
          <input
            type="email"
            name="confirmEmail"
            placeholder="Confirm Email*"
            required
          />
        </div>
        <div className={isPasswordValid ? "" : "errorInput"}>
          <input
            type="password"
            name="password"
            placeholder="Password*"
            required
            onBlur={(e) => setIsPasswordValid(validatePassword(e.target.value))}
          />
          <ul>
            <li>Minimum 4 characters - Maximum 10 characters</li>
            <li>No special characters or symbols</li>
          </ul>
        </div>
        <div>
          <input
            type="password"
            name="passwordConfirm"
            placeholder="Confirm Password*"
            required
          />
        </div>
        <div>
          <input
            type="phone"
            name="phone"
            placeholder="Phone number (required)"
            required
          />
        </div>
        <div>
          <input
            type="text"
            name="promo"
            readOnly={aff ? true : false}
            defaultValue={aff ?? ""}
            placeholder="Affiliate Code"
          />
        </div>
        <div className="checkbox">
          <input type="checkbox" id="terms" name="terms" value="" required />
          <label htmlFor="terms">
            Agree to <span>terms and conditions</span>
          </label>
        </div>
        <p className="note">
          NOTE: Automated betting systems, sharp action, past post wagers or any
          other exploitation of our offerings is prohibited. Assigned wager
          limits on the account are to be respected at all times. Any attempt to
          override these wager limits is prohibited. Any account that has been
          determined to have violated these terms may risk account closure and
          forfeiture of funds. Successive accounts created through individuals
          and/or syndicates or with matching IP addresses will also be flagged.
          By entering your number, you agree to receive mobile messages. Message
          frequency varies. Message and data rates may apply. View our{" "}
          <a href="#" onClick={(e) => handleOpenModal(e, "privacy")}>
            Privacy Policy
          </a>{" "}
          and{" "}
          <a href="#" onClick={(e) => handleOpenModal(e, "sms")}>
            SMS Terms
          </a>{" "}
          (Reply STOP to unsubscribe).
        </p>
        <div className="captcha">
          <ReCAPTCHA
            sitekey={"6LcL4OopAAAAADcMmgiSH9meAkuM-k-4UUjNGTPT"}
            ref={captchaRef}
          />
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className={isLoading ? "disabled" : ""}
          onClick={(e) => (!isLoading ? handleSubmit(e) : null)}
        >
          REGISTER
        </button>
      </form>

      <ModalComponent
        visible={visibleModal}
        closeModal={() => setVisibleModal(false)}
        type={modalType}
      />
    </div>
  );
};
