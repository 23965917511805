import React from "react";

import styles from "../../styles/banners-container.module.scss";

export const BannersContainerComponent: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles["container__desktop"]}>
        <div className={styles.banner}>
          <div className={styles["banner__header"]}>
            <h3>EVERY ELIGIBLE DEPOSIT</h3>
            <div className={styles.arrow}></div>
          </div>
          <div className={styles["banner__content"]}>
            <img src="/assets/img/banner_s_1.png" alt="" />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles["banner__header"]}>
            <h3>More Stake in the Game</h3>
            <div className={styles.arrow}></div>
          </div>
          <div className={styles["banner__content"]}>
            <img src="/assets/img/banner_s_2.png" alt="" />
          </div>
        </div>
        <div className={styles.banner}>
          <div className={styles["banner__header"]}>
            <h3>We Have it All</h3>
            <div className={styles.arrow}></div>
          </div>
          <div className={styles["banner__content"]}>
            <img src="/assets/img/new_banner_wager.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
