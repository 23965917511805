import React from "react";
import styles from "./styles/slider.module.scss";

import { SwitchModalType } from "./interfaces";
import LayoutContainer from "./layouts/container";
import { FooterComponent } from "./components/ui/Footer.component";
import { SliderComponent } from "./components/slider/Slider.component";
import { DynamicContentComponent } from "./components/ui/DynamicContent.component";
import { CasinoContainerComponent } from "./components/ui/CasinoContainer.component";
import { BannersContainerComponent } from "./components/ui/BannersContainer.component";

function App() {
  const [switchModal, setSwitchModal] = React.useState<SwitchModalType>();

  const openModal = (modal: SwitchModalType) => setSwitchModal(modal);

  return (
    <LayoutContainer openModal={openModal} switchModal={switchModal}>
      <SliderComponent openModal={openModal} />
      <div className={styles.bg3}>
        <p>Bonus on Every Eligible Deposit</p>
      </div>
      <BannersContainerComponent />
      <div className="page-container">
        <CasinoContainerComponent
          clickOnBanner={() => setSwitchModal("login")}
        />
        <DynamicContentComponent />
      </div>
      <FooterComponent />
    </LayoutContainer>
  );
}

export default App;
