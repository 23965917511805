import { AllowedPaymentMethods } from "../interfaces";

export const allowedPaymentMethods: AllowedPaymentMethods[] = [
  {
    name: "Bitcoin",
    icon: "bitcoin.png",
  },
  {
    name: "B",
    icon: "b.jpeg",
  },
  {
    name: "Ethereum",
    icon: "eth.jpeg",
  },
  {
    name: "d",
    icon: "d.jpeg",
  },
];

export const benefitsList: AllowedPaymentMethods[] = [
  {
    name: "<p class='card-title'>Any day <span>payouts</span></p>",
    icon: "landing.4.png",
  },
  {
    name: "<div><p class='card-title'>Free play match</p><p>on Every Deposit</p></div>",
    icon: "landing.3.png",
  },
  {
    name: "<div><p class='card-title'>crypto accounts</p><p>+ Boosts on every Deposit</p></div>",
    icon: "landing.2.png",
  },
];
