import React from "react";
import styles from "../../styles/header.module.scss";
import { SwitchModalType } from "../../interfaces";

import { SocialsMobileComponent } from "./SocialsMobile.component";
import { AuthContainerComponent } from "./AuthContainer.component";
import { PaymentMethodsComponent } from "./PaymentMethods.component";

interface Props {
  hiddenForm?: boolean;
  openModal: (modal: SwitchModalType) => void;
}

export const HeaderComponent: React.FC<Props> = ({ openModal, hiddenForm }) => {
  return (
    <header className={styles.header}>
      <div className={styles["header-container"]}>
        <div className={styles["header-container__logo"]}>
          <img src="/assets/img/logo.png" alt="Wager Attack Logo" />
        </div>
        <div
          className={`${styles["header-container__options"]} ${
            hiddenForm ? styles["header-container__options_crypto"] : ""
          }`.trim()}
        >
          <div className={`${styles.option} ${styles["option--auth"]}`}>
            <AuthContainerComponent openModal={openModal} />
          </div>
          <div className={styles.socialDesktop}>
            <SocialsMobileComponent />
          </div>
        </div>
      </div>
      <div className={`${styles.option} ${styles["option--pay-methods"]}`}>
        <PaymentMethodsComponent />
      </div>
    </header>
  );
};
