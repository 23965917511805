import axios from "axios";

const apiUrl =
  "https://sp.sportsaction77.com/tooltip/app/admin/frontend/frontend.php";

const api = axios.create({
  baseURL: apiUrl,
});

const apiAuth = axios.create({
  baseURL: "https://wager.wagerattack.ag/cloud/api/System/authenticateCustomer",
  headers: {
    origin: "https://wager.wagerattack.ag",
  },
});

const apiPassword = axios.create({
  baseURL: "https://wager.wagerattack.ag/sites/wagerattack.ag/recoverPass.php",
});

const apiCheck = axios.create({
  baseURL: "https://wager.wagerattack.ag/sites/wagerattack.ag/checkEmail.php",
});

interface FormDataElement {
  key: string;
  value: string;
}

const getData = async (operation: string, params: FormDataElement[] = []) => {
  const formData = new FormData();
  formData.append("operation", operation);
  params.forEach((param) => {
    formData.append(param.key, param.value);
  });
  const response = await api.post(``, formData);
  return response.data;
};

export const getNews = async () => {
  return await getData("getNews");
};

export const getGames = async () => {
  return await getData("getGames");
};

export const getVideos = async () => {
  return await getData("getVideo");
};

export const getMessage = async () => {
  return await getData("getMessage");
};

export const loadSlider = async () => {
  return await getData("loadSlider");
};

export const getCountDown = async () => {
  const category = "COUNTDOWN";
  const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return await getData("getCountDown", [
    { key: "category", value: category },
    { key: "zone", value: zone },
  ]);
};

export const login = async (username: string, password: string) => {
  const formData = new FormData();
  formData.append("customerID", username);
  formData.append("state", "true");
  formData.append("password", password);
  formData.append("multiaccount", "0");
  formData.append("response_type", "code");
  formData.append("client_id", username);
  formData.append("domain", "wagerattack.ag");
  formData.append("redirect_uri", "wagerattack.ag");
  formData.append("operation", "authenticateCustomer");
  formData.append("PRO", "1");

  const response = await apiAuth.post(``, formData);
  return response.data;
};

export const resetPassword = async (email: string) => {
  const formData = new FormData();
  formData.append("email", email);
  const response = await apiPassword.post(``, formData);
  return response.data;
};

export const checkEmail = async (email: string) => {
  const formData = new FormData();
  formData.append("email", email);
  try {
    const response = await apiCheck.post(``, formData);
    return response.data;
  } catch (error) {
    return { status: "error", message: "Error checking email" };
  }
};
