import React from "react";

import { SwitchModalType } from "../interfaces";
import { SignInForm } from "../components/ui/SignInForm.component";
import { HeaderComponent } from "../components/ui/Header.component";
import { ForgotFormComponent } from "../components/ui/ForgotForm.component";
import { RegisterFormComponent } from "../components/ui/RegisterForm.component";

interface Props {
  hiddenForm?: boolean;
  children: React.ReactNode;
  switchModal?: SwitchModalType;
  openModal: (modal: SwitchModalType) => void;
}

function LayoutContainer({
  children,
  hiddenForm,
  switchModal,
  ...props
}: Readonly<Props>) {
  const [openModal, setOpenModal] = React.useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const aff = urlParams.get("aff");

  React.useEffect(() => {
    if (aff) {
      handlerSwitchModal("register");
    }
  }, [aff]);

  React.useEffect(() => {
    if (switchModal) handlerSwitchModal(switchModal);
  }, [switchModal]);

  const handlerSwitchModal = (modal: SwitchModalType) => {
    props.openModal?.(modal);
    setOpenModal(true);
  };

  const handleClose = () => {
    props.openModal?.(undefined);
    setOpenModal(false);
  };

  return (
    <div className={`App ${openModal ? "modal-active" : ""}`}>
      <HeaderComponent hiddenForm={hiddenForm} openModal={handlerSwitchModal} />
      {children}
      <div className={`modal ${openModal ? "modal--active" : ""}`}>
        <div className="overlay">
          <div className="modal-card">
            {switchModal === "login" && (
              <SignInForm
                closeModal={handleClose}
                switchModal={handlerSwitchModal}
              />
            )}
            {switchModal === "register" && (
              <RegisterFormComponent
                closeModal={handleClose}
                switchModal={handlerSwitchModal}
              />
            )}
            {switchModal === "forgot" && (
              <ForgotFormComponent
                closeModal={handleClose}
                switchModal={handlerSwitchModal}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LayoutContainer;
