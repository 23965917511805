import "./styles.scss";
import React from "react";

import { SwitchModalType } from "../../interfaces";
import styles from "../../styles/slider.module.scss";

import LayoutContainer from "../../layouts/container";
import CryptoStepOne from "../../components/crypto/step-one";
import CryptoStepTwo from "../../components/crypto/step-two";
import CryptoStepThree from "../../components/crypto/step-three";

const CryptoPage = () => {
  const [switchModal, setSwitchModal] = React.useState<SwitchModalType>();

  const openModal = (modal: SwitchModalType) => setSwitchModal(modal);
  return (
    <LayoutContainer openModal={openModal} hiddenForm switchModal={switchModal}>
      <main className="crypto">
        <div className={styles.container}>
          <div className={styles.slider}>
            <div className={`${styles.slide} ${styles["slide--static"]}`}>
              <div className={styles.content}>
                <div className={styles.bg2} style={{ display: "grid" }}>
                  <img
                    src="/assets/img/slides/bg-slide-1.svg"
                    alt="bg-slide-1"
                    style={{ objectFit: "contain", width: "100%" }}
                  />
                </div>
                <div className={styles.bg5}>
                  <img
                    src="/assets/img/slides/bg-slide-1-desk.svg"
                    alt="bg-slide-1-desk"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* CRYPTOCURRENCIES */}
        <div className="crypto-hero">
          <div className="crypto-hero wrap">
            <h2>
              HOW TO DEPOSIT WITH <span>CRYPTOCURRENCIES</span>
            </h2>
            <div className="crypto-hero_content">
              <p>
                This tutorial will guide you to deposit Bitcoin or any of our
                featured altcoins from your secure digital wallet to your Wager
                Attack account!
              </p>
              <p>
                Already know how to deposit with cryptocurrencies?{" "}
                <a href="">Click here</a>{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="crypto_steps">
          <CryptoStepOne />
          <CryptoStepTwo />
          <CryptoStepThree />
        </div>
        <div className="crypto_exchange">
          <div className="flex flex:column gap:6 crypto_exchange__content">
            <h2>
              <span>Get Started</span> - Choose Exchange
            </h2>
            <div className="exchange-wrap_img">
              <img
                className="exchange-img"
                src="/assets/img/wager/coinbase.png"
                alt="coinbase"
              />
              <img
                className="exchange-img"
                src="/assets/img/wager/kraken.png"
                alt="kraken"
              />
              <img
                className="exchange-img"
                src="/assets/img/wager/crypto.png"
                alt="crypto"
              />
              <img
                className="exchange-img"
                src="/assets/img/wager/gemini.png"
                alt="gemini"
              />
            </div>
            <p>
              You can also buy crypto currency via{" "}
              <strong className="highlight">CashApp</strong> and other payment
              services <br />{" "}
              <small>
                <strong>*Associated with higher fees*</strong>
              </small>
              <br />
              Or using cash at a{" "}
              <strong className="highlight">Crypto ATM.</strong>
            </p>
            {/* <button>GO TO CASHIER</button> */}
          </div>
        </div>
        <div className="crypto_footer">
          <h4 className="text:center">
            Still have questions? Contact us via Live Chat
          </h4>
        </div>
      </main>
    </LayoutContainer>
  );
};

export default CryptoPage;
